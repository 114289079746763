import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
export const frontmatter = {
  title: 'Uploadcare bug bounty program',
  description: '',
  header: 'Bug bounty program'
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`No technology is perfect, and Uploadcare believes that working with skilled security researchers across the globe is crucial in identifying weaknesses in any technology. If you believe you've found a security issue in our product or service, we encourage you to notify us. We welcome working with you to resolve the issue promptly.`}</p>
    <p>{`To show our appreciation of responsible security researchers, Uploadcare offers a monetary bounty for reports of qualifying security vulnerabilities. Reward amounts will vary based upon the severity of the reported vulnerability, and eligibility is at our sole discretion.`}</p>
    <p>{`Contact us at `}<a parentName="p" {...{
        "href": "mailto:bugbounty@uploadcare.com"
      }}>{`bugbounty@uploadcare.com`}</a>{`.`}</p>
    <h3 {...{
      "id": "disclosure-policy"
    }}><a parentName="h3" {...{
        "href": "#disclosure-policy"
      }}>{`Disclosure policy`}</a></h3>
    <ul>
      <li parentName="ul">{`Let us know as soon as possible upon discovery of a potential security issue, and we'll make every effort to quickly resolve the issue.`}</li>
      <li parentName="ul">{`Provide us a reasonable amount of time to resolve the issue before any disclosure to the public or a third-party.`}</li>
      <li parentName="ul">{`Make a good faith effort to avoid privacy violations, destruction of data, and interruption or degradation of our service. Only interact with accounts you own or with explicit permission of the account holder.`}</li>
    </ul>
    <h3 {...{
      "id": "scope"
    }}><a parentName="h3" {...{
        "href": "#scope"
      }}>{`Scope`}</a></h3>
    <p>{`In scope:`}</p>
    <ul>
      <li parentName="ul">{`uploadcare.com`}</li>
      <li parentName="ul">{`api.uploadcare.com`}</li>
      <li parentName="ul">{`app.uploadcare.com`}</li>
      <li parentName="ul">{`social.uploadcare.com`}</li>
      <li parentName="ul">{`upload.uploadcare.com`}</li>
      <li parentName="ul">{`ucarecdn.com`}</li>
    </ul>
    <p>{`Out of scope:`}</p>
    <ul>
      <li parentName="ul">{`community.uploadcare.com`}</li>
      <li parentName="ul">{`help.uploadcare.com`}</li>
      <li parentName="ul">{`status.uploadcare.com`}</li>
    </ul>
    <h3 {...{
      "id": "exclusions"
    }}><a parentName="h3" {...{
        "href": "#exclusions"
      }}>{`Exclusions`}</a></h3>
    <p>{`While researching, we'd like to ask you to refrain from:`}</p>
    <ul>
      <li parentName="ul">{`Denial of service`}</li>
      <li parentName="ul">{`Spamming`}</li>
      <li parentName="ul">{`Social engineering (including phishing) of Uploadcare staff or contractors`}</li>
    </ul>
    <h3 {...{
      "id": "were-not-accepting"
    }}><a parentName="h3" {...{
        "href": "#were-not-accepting"
      }}>{`We're not accepting`}</a></h3>
    <ul>
      <li parentName="ul">{`CORS related issues for upload.uploadcare.com and ucarecdn.com`}</li>
      <li parentName="ul">{`"Lack of Best Practices" reports`}</li>
    </ul>
    <h3 {...{
      "id": "what-we-like-to-see-in-reports-where-applicable"
    }}><a parentName="h3" {...{
        "href": "#what-we-like-to-see-in-reports-where-applicable"
      }}>{`What we like to see in reports (where applicable)`}</a></h3>
    <ul>
      <li parentName="ul">{`Step-by-step instructions to reproduce a bug`}</li>
      <li parentName="ul">{`cURL commands/scripts that can be used to automate reproducing
screenshots`}</li>
    </ul>
    <p>{`Thank you for helping keep Uploadcare and our users safe!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      